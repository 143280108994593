<template>
  <div v-if="seance">
    <router-link
      :to="{name: 'seances', }"
      class="blue-link"
    >
      <i class="fa fa-arrow-left" />
      Retourner à la liste des séances
    </router-link>
    <div>
      <div
        v-if="isAdmin"
        id="share_to_user"
      >
        <h2>Rechercher un utilisateur à qui partager la séance: </h2>
        <v-select
          v-model="selectedUser"
          :options="users"
          label="verbose"
          @search="fetchUsers"
        />
        <div class="button-container">
          <button
            :disabled="!selectedUser"
            @click="sharedDirectly"
          >
            Partager
        </button>
        </div>
      </div>
      <h2>
        Partager une séance par QRCode
      </h2>
      <p>
        Cette fonctionnalité permet de partager des séances entre sportifs.
        Il suffit de scanner ce QRCode depuis la liste des séances.
      </p>
      <div id="qrcode">
        <h2>{{seance.nom}}</h2>
        <VueQrcode
          :text="`seance-${seance.uuid}`"
          width="100%"
          :correctLevel="3"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/modules/axios";
import VueQrcode from "vue-qr";
import { mapGetters } from "vuex";
import { catchError } from "@/utils";

export default {
  name: "SeanceQrCode",
  components: {
    VueQrcode,
  },
  data() {
    return {
      seance: false,
      users: [],
      selectedUser: null,
    };
  },
  computed: {
    ...mapGetters(["token", "isAdmin"]),
  },
  mounted() {
    this.getSeance();
  },
  methods: {
    fetchUsers(search, loading) {
      loading(true);
      Api().get(`/utilisateur/?search=${search}`)
        .then((res) => {
          this.users = res.data.results;
          loading(false);
        });
    },
    getSeance() {
      Api().get(`/seance/${this.$route.params.id}/`)
        .then((res) => {
          // this.seance = res;
          this.seance = res.data;
        });
    },
    sharedDirectly() {
      this.scaning = false;
      Api().post("/seance/partage/", { uuid: this.seance.uuid, user_id: this.selectedUser.id })
        .then(() => {
          this.$toast("La séance a bien été partagée");
          this.selectedUser = null;
        })
        .catch(catchError);
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/variables.scss" as var;

#qrcode {
  h2 {
    margin-bottom: 0;
  }
}

.camera {
  position: relative;
  .wait-camera {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; height: 100%;
    .img-container > div img {
      height: 50%;
    }
  }
}
.qrcode-stream-wrapper{
  border-radius: var.$border-radius;
  overflow: hidden;
}

#share_to_user {
  .v-select {
    width: 100%;
  }
  border-bottom: 1px solid black;
  padding-bottom: 2rem;
  margin-bottom: 4rem;
}
</style>
