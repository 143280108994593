<template>
  <PulseLoader
    v-if="!elements"
  />
  <div
    class="deleted-elements"
    v-else
  >
    <h2>{{ title }}</h2>
    <a
      @click="$emit('cancel', )"
      class="blue-link"
    >
      <i class="fa fa-arrow-left" />
      Retour
    </a>
    <div class="search-bar">
      <input
        class=""
        v-model="searchText"
        type="text"
        placeholder="Rechercher un élément par son nom"
        @keyup.enter="currentPage=1;getInfos()"
      />
      <i
        class="fa fa-search"
        @click="currentPage=1;getInfos()"
      />
    </div>
    <ul
      v-if="elements.length"
      class="elements"
    >
      <li class="legend">
        <div class="nom">Nom</div>
        <div class="date">Suppression</div>
        <div>Restaurer</div>
      </li>
      <li
        v-for="element in elements"
        :key="element.id"
      >
        <div class="nom">
          {{ element.nom }}
        </div>
        <div class="date">
          {{ element.date_archivage | moment("DD/MM/yyyy") }}
        </div>
        <div>
          <i
            class="fa fa-trash-restore-alt"
            @click="restore(element,)"
          />
        </div>
      </li>
    </ul>
    <div
      v-else
      class="img-container tiny"
    >
      <div>
        <h2>Aucun élément {{searched ? "ne correspond à votre recherche" : ""}}</h2>
        <img :src="require('@/assets/empty.png', )" />
      </div>
    </div>

    <Pagination
      v-model="currentPage"
      :total-results="resultsCount"
      @paginate="getInfos"
    />
  </div>
</template>

<script>
import Api from "@/modules/axios";
import Pagination from "@/components/Pagination.vue";

export default {
  name: "DeletedElementsList",
  components: {
    Pagination,
  },
  props: {
    title: String,
    model: String,
  },
  data() {
    return {
      elements: null,

      searched: false,
      searchText: "",
      currentPage: 1,
      resultsCount: 0,
    };
  },
  mounted() {
    this.getInfos();
  },
  methods: {
    getInfos() {
      this.elements = null;
      Api().get(
        `/${this.model}/?archived=true&public=false&search=${this.searchText}&page=${this.currentPage}&page_size=15`,
      )
        .then((res) => {
          this.searched = !!this.searchText;
          this.elements = res.data.results;
          this.resultsCount = res.data.count;
        });
    },
    restore(element) {
      Api().patch(
        `/${this.model}/${element.id}/?archived=both`,
        { date_archivage: null },
      )
        .then(() => {
          this.getInfos();
          this.$toast("Élement restauré");
        });
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/variables.scss" as var;

.deleted-elements {
  ul.elements {
    margin-top: 1rem;
    li {
      display: flex;
      align-items: center;
      padding: .5rem 2rem;
      justify-content: space-around;
      &.legend {
        font-weight: bold;
      }
      &:not(.legend) {
        background: var.$background-gray;
        border: 1px solid var.$border-gray;
        border-radius: var.$border-radius;
      }
      & > *:not(.nom) {
        text-align: center;
      }
      & + li {
        margin-top: 0.5rem;
      }
      .fa {
        background: var.$red;
        color: white;
        padding: 1rem;
        font-size: 1rem;
        border-radius: var.$border-radius;
        position: relative;
        cursor: pointer;
      }
      div:nth-child(1) {
        width: 40%;
      }
      div:nth-child(2) {
        width: 40%;
      }
      div:nth-child(3) {
        width: 20%;
      }
    }
  }
}
</style>
