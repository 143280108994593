<template>
  <PulseLoader
    v-if="loading"
    loading
  />
  <div v-else-if="perfs.length">
    <template v-if="!exerciceId">
      <router-link
        class="blue-link"
        :to="{name:'exercices', }"
      >
        <i class="fa fa-arrow-left" />
        Retourner à la liste des exercices
      </router-link>
      <h2>
        {{ exercice.nom }}
      </h2>
    </template>
    <ApexChart
      width="100%"
      type="line"
      :options="chartOptions"
      :series="perfs"
    />
    <template v-if="!exerciceId">
      <div class="serie-selector">
        <button
          v-for="s in maxSerie"
          :key="s"
          :class="{active: selectedSerie[s], }"
          @click="handleSelectSerie(s, );"
        >
          Série n°{{ s }}
        </button>
      </div>
      <hr />
      <table>
        <thead>
          <tr>
            <td>Date</td>
            <td>Poids* (kg)</td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="p in perfs[0].data"
            :key="p[0]"
          >
            <td>{{ p[0]|moment("DD/MM/yyyy") }}</td>
            <td>{{ p[1] }}</td>
          </tr>
        </tbody>
      </table>
      <em>* Les données utilisées sont basées sur la dernière série de chaque jour.</em>
    </template>
  </div>
</template>

<script>
import Api from "@/modules/axios";
import ApexChart from "vue-apexcharts";

export default {
  name: "ExerciceStats",
  props: {
    exerciceId: {
      type: Number,
      required: false,
    },
  },
  components: {
    ApexChart,
  },
  data() {
    return {
      exercice: {},
      tmpPerfs: [],
      perfs: [],
      loading: false,
      selectedSerie: {},
      chartOptions: {
        chart: {
          foreColor: "#000",
          width: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            format: "dd/MM",
          },
        },
      },
    };
  },
  mounted() {
    this.getInfosExo();
  },
  methods: {
    handleSelectSerie(serie) {
      const nbSelected = Object.values(this.selectedSerie).filter((s) => s === true).length;
      if (this.selectedSerie[serie]) {
        if (nbSelected !== 1) {
          this.perfs = this.perfs.filter((p) => p.serie !== serie);
          this.selectedSerie[serie] = false;
        }
      } else {
        this.selectedSerie[serie] = true;
        this.getInfosExo(serie);
      }
    },
    getInfosExo(serie) {
      let url = `/exercice/${this.exerciceId || this.$route.params.id}/perfs/`;

      if (serie) {
        url += `?serie=${serie}`;
      }

      this.loading = true;
      return Api().get(url)
        .then((res) => {
          this.exercice = res.data.exercice;
          this.tmpPerfs = res.data.perfs;
          if (!this.maxSerie) {
            this.maxSerie = res.data.max_serie;
            this.selectedSerie[this.maxSerie] = true;
          }
          const currSerie = parseInt(serie || this.maxSerie, 10);
          this.perfs.push(
            {
              serie: currSerie,
              // name: "Poids (kg)",
              name: `Serie ${currSerie}`,
              data: res.data.perfs.map((p) => [parseInt(p.date * 1000, 10), p.poids]),
            },
          );
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/variables.scss" as var;

table {
  margin-top: 2rem;
  width: 100%;
  text-align: center;
}
thead {
  tr {
    background: var.$red;
    color: white;
  }
  td {
    font-weight: bold;
    padding: 0.5rem 1rem;
  }
}
em {
  display: block;
  margin-top: 2rem;
}
.serie-selector {
  display: flex;
  align-items: center;
  justify-content: space-around;
  button {
    background: none;
    border: 1px solid black;
    border-radius: var.$border-radius;
    padding: 0.5rem 1rem;
    &:hover {
      background: var.$red;
      cursor: pointer;
      color: white;
    }
    &.active {
      background: var.$red;
      color: white;
      &:hover {
        background: none;
        color: unset;
      }
    }
  }
}
hr {
  margin: 4rem 0;
}
</style>
