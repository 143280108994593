<template>
  <PulseLoader
    v-if="loading"
    loading
  />
  <div
    v-else-if="forbidden"
    class="img-container"
  >
    <div>
      <h2>Vous n'avez pas accès à ceci</h2>
      <img :src="require('@/assets/forbidden.png', )" />
      <a
        class="blue-link"
        @click="$router.go(-1,)"
      >
        Retour
      </a>
    </div>
  </div>
  <div
    v-else-if="seance && !addingExo"
    id="seance_details"
  >
    <router-link
      class="blue-link"
      :to="{name:'seances', }"
    >
      <i class="fa fa-arrow-left" />
      Retourner à la liste des séances
    </router-link>
    <h2>
      <template v-if="isNew">
        Création d'une séance
      </template>
      <template v-else-if="!disabled">
        Modifier la séance "{{oldSeanceName}}"
      </template>
      <template v-else>
        {{oldSeanceName}}
      </template>
    </h2>
    <div class="see-body-container">
      <button
        class="btn secondary outline"
        @click="displayModalMuscles = true"
      >
        <i class="fa fa-person"/>
      </button>
    </div>
    <form>
      <div
        v-if="isAdmin"
        class="form-field public"
      >
        <label>Admin: rendre accessible à tout le monde&nbsp;:</label>
        <div class="field no-border">
          <input
            v-model="seance.new_public"
            type="checkbox"
            :disabled="disabled"
          />
        </div>
      </div>
      <FloatingInput
        label="Nom"
        v-model="seance.nom"
      />
      <FloatingInput
        label="Commentaire"
        v-model="seance.commentaire"
        :disabled="disabled"
        type="textarea"
      />
      <div
        v-if="!disabled"
        class="form-field icons"
      >
        <div>
          <label>Icône</label>
          <div
            class="icons-list"
          >
            <img
              v-for="icon in icons"
              :key="icon"
              :class="{'active': seance.icon === icon, }"
              :src="require(`@/assets/muscles/${icon}.png`, )"
              @click="$set(seance, 'icon', icon, ); displayIcons=false;"
            />
          </div>
        </div>
      </div>

      <div>
        <p
          v-if="!disabled"
          class="drag-info"
        >
          <i class="fa fa-info-circle"/>&nbsp;Restez appuyé sur une étape pour la déplacer
      </p>
        <ul class="steps">
          <Draggable
            delay="500"
            :delay-on-touch-only="true"
            v-model="seance.steps"
            tag="ul"
            @start="handleMoving($event, true, )"
            @end="handleMoving($event, false, true, )"
            @choose="handleMoving($event, true, )"
            @unchoose="handleMoving($event, false, )"
            class="no-text-select"
            :disabled="disabled"
          >
            <li
              v-for="(groupStep, groupStepIndex) in seance.steps"
              :key="groupStepIndex"
              :id="`step-index-${groupStepIndex}`"
              class="step"
              :class="{
                [groupStep.type]: true,
                dragging: groupStep.drag,
              }"
            >
              <span class="exo-name">
                {{groupStep.nom}}
                <i
                  v-if="!disabled"
                  class="fa fa-times suppr"
                  @click="deleteGroup(groupStep, )"
                />
              </span>
              <div v-if="groupStep.type === 'warmup'">
                <FloatingInput
                  placeholder="Exemple : 10 minutes de rameur puis une série à vide."
                  v-model="groupStep.step.notes_echauffement"
                  :disabled="disabled"
                  type="textarea"
                />
              </div>
              <template v-else-if="groupStep.type === 'exo' ">
                <ul>
                  <li
                  v-for="(step, stepIndex) in groupStep.steps"
                  :key="step.id"
                  :class="{
                    [step.type]: true,
                    dragging: step.drag,
                  }"
                  >
                    <span class="serie">Série {{stepIndex+1}}&nbsp;:</span>
                    <input
                      placeholder="10"
                      v-model="step.nb_repetition"
                      :disabled="disabled"
                    /> reps.
                    <span
                      class="avec-repos"
                      :class="{'less-opacity': step.duree_repos === 0, }"
                    >
                      avec
                      <span class="repos-input">
                        <i
                          v-if="!disabled"
                          class="fa fa-times suppr-repos"
                          :class="{ hidden: step.duree_repos === 0, }"
                          @click="step.duree_repos = 0"
                        />
                        <input
                          placeholder="90"
                          v-model="step.duree_repos"
                          :disabled="disabled"
                        /> s de repos
                      </span>
                    </span>
                    <i
                      v-if="!disabled"
                      class="fa fa-times suppr"
                      @click="deleteSteps([step.id || step.uuid, ], )"
                    />
                    <div
                      class="modifiers"
                    >
                      <div
                        class="modifier-item"
                        :class="{'active': step.degressif, }"
                        @click="!disabled ? $set(step, 'degressif', !step.degressif, ) : null"
                      >
                        <i class="fa fa-arrow-trend-down" />
                        Dégressif
                      </div>
                      <div
                        class="modifier-item"
                        :class="{'active': step.longue, }"
                        @click="!disabled ? $set(step, 'longue', !step.longue, ) : null"
                      >
                        <i class="fa fa-stopwatch" />
                        Isométrique
                      </div>
                    </div>
                  </li>
                </ul>
                <i
                  v-if="groupStep.type === 'exo' && !disabled"
                  class="fa fa-add"
                  @click="duplicate(groupStep, groupStepIndex, )"
                />
                <div
                  v-if="groupStepIndex !== seance.steps.length-1"
                  class="superset-toggle"
                  @click="!disabled ? toggleSuperSet(groupStep, ) : null"
                  :class="{active: groupStep.superset,}"
                >
                  <i :class="`fa fa-link${groupStep.superset ? '' : '-slash'}`" />&nbsp;
                  <span v-if="groupStep.superset">Super-set *</span>
                </div>
              </template>
            </li>
          </Draggable>
        </ul>
        <div
          v-if="!disabled"
          class="btns-add"
        >
          <button @click.prevent="addEchauffement">
            <i class="fa fa-temperature-high" />
            Ajouter un<br/>echauffement
          </button>
          <button @click.prevent="addingExo = true">
            <i class="fa fa-dumbbell" />
            Ajouter un<br/>exercice
          </button>
        </div>
        <p
          v-if="hasSuperSet"
          class="superset-help"
        >
          * Si A et B sont liés en super-set, ils s'exécuteront dans l'ordre A, B, A, B, A, B...
        </p>
      </div>
    </form>
    <div
      v-if="seance.public && isAdmin && !forceEdit"
      class="button-container"
    >
      <button @click.prevent="forceEdit = true">
        Admin: Activer l'édition
      </button>
    </div>

    <template v-if="!disabled">
      <div class="button-container">
        <button @click="saveSeance">Enregistrer</button>
      </div>
      <ValidationButton
        v-if="!isNew"
        text="Annuler"
        secondary
        @validated="$router.push({name:'seances', }, )"
      />
      <ValidationButton
        class="suppr-seance"
        v-if="!isNew"
        text="Supprimer la séance"
        secondary
        @validated="deleteSeance"
      />
    </template>
    <div
      v-else
      class="button-container"
    >
      <button
        class="secondary"
        @click="dupliquer"
      >
        Copier et modifier cette séance
      </button>
    </div>
    <div
      id="shared_seance_user"
      v-if="!seance.public && seance.user_created && seance.user_created.id === user.id"
    >
      <h2>Séance partagée avec</h2>
      <ul>
        <li
          v-for="partage in seance.partages"
          :key="partage.id"
        >
          {{partage.first_name}} {{partage.last_name}}
          <i
            class="suppr fa fa-times"
            @click="unShare(partage.id, )"
          />
        </li>
        <li v-if="!seance.partages.length">Aucun partage actuellement</li>
      </ul>
    </div>

    <transition
      name="slide-left"
    >
      <Modal
        v-if="displayModalMuscles"
        title="Muscles solicités par la séance"
        @ask-close="displayModalMuscles = false"
        class="modal-exo"
      >
        <VueBodyPartSelector
          v-model="allMuscles"
          disabled
        />
      </Modal>
    </transition>
  </div>
  <ExoChoose
    v-else-if="addingExo"
    @cancel="addingExo=false"
    @select="addExo"
  />
  <div v-else>
    Chargement
  </div>
</template>

<script>
import Api from "@/modules/axios";
import { catchError, parseSeance } from "@/utils";
import ValidationButton from "@/components/ValidationButton.vue";
import FloatingInput from "@/components/FloatingInput.vue";
import Modal from "@/components/Modal.vue";
import ExoChoose from "@/components/ExoChoose.vue";
import { v4 as uuid } from "uuid";
import Draggable from "vuedraggable";
import { mapGetters } from "vuex";
import VueBodyPartSelector from "@/components/VueBodyPartSelector.vue";

export default {
  name: "SeanceDetails",
  components: {
    ValidationButton,
    ExoChoose,
    Draggable,
    Modal,
    VueBodyPartSelector,
    FloatingInput,
  },
  data() {
    return {
      seance: null,
      oldSeanceName: null,
      loading: false,
      addingExo: false,
      forceEdit: false,
      displayModalMuscles: false,
      forbidden: false,
      icons: [
        "abdo", "avant-bras", "biceps", "cou", "dos", "epaules", "ischio",
        "jambes", "mollets", "pecs", "quadriceps", "torse", "triceps",
      ],
    };
  },
  watch: {
    "$route.params.id": {
      handler() {
        this.getInfoSeance();
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["isAdmin", "user"]),
    isNew() {
      return this.$route.params.id === "new";
    },
    onlyExo() {
      if (!this.seance.seance_exercices) {
        return [];
      }

      return this.seance.seance_exercices.filter((s) => s.type === "exo");
    },
    disabled() {
      const notMine = this.seance.user_created && this.seance.user_created.id !== this.user.id;
      const isPublic = this.seance.public;
      const adminIsEditing = this.isAdmin && this.forceEdit;

      return notMine || (isPublic && !adminIsEditing);
    },
    allMuscles() {
      const allMusclesP = [...new Set(
        this.onlyExo.map((s) => (s.exercice.muscles_1 || "").split(",")).flat().filter((m) => m),
      )];
      const allMusclesS = [...new Set(
        this.onlyExo.map((s) => (s.exercice.muscles_2 || "").split(",")).flat().filter((m) => m),
      )];

      const res = {};
      allMusclesS.forEach((m) => {
        res[m] = "secondary";
      });
      allMusclesP.forEach((m) => {
        res[m] = "primary";
      });

      return res;
    },
    hasSuperSet() {
      // sur steps plutot que seance_exercices car moins nombreuses donc plus opti
      return this.seance.steps && this.seance.steps.some((se) => se.superset);
    },
    lastStep() {
      if (!this.seance.steps) {
        return null;
      }

      let last = this.seance.steps.at(-1);

      if (last.steps) {
        last = last.steps.at(-1);
      }

      return last;
    },
  },
  mounted() {
    if (!this.user.beta_accepted) {
      alert("Vous n'êtes pas autorisé à accéder à cette page");
      return this.$router.push({ name: "accueil" });
    }
    return true;
  },
  methods: {
    dupliquer() {
      Api().post(`/seance/${this.seance.id}/copy/`)
        .then((res) => {
          this.$router.push({ name: "seance-details", params: { id: res.data.id } });
          this.$toast("Séance dupliquée");
        });
    },
    handleMoving(event, moving, end) {
      try {
        // permet de ne pas drag si c'est un click sur les modifiers
        if (
          event.explicitOriginalTarget.parentElement.className.includes("modifier-item")
          || event.explicitOriginalTarget.className.includes("modifier-item")
        ) {
          return false;
        }
      } catch (error) {
        // rien
      }

      const movingStep = this.seance.steps[event.oldDraggableIndex];

      if (!moving) {
        // Semble y avoir un bug lors de la désélection, donc on met tout à false.
        this.seance.steps.forEach((s) => {
          this.$set(s, "drag", false);
        });
      } else {
        this.$set(movingStep, "drag", moving);
      }

      if (end) {
        // Après le déplacement d'une étape, on réordonne les vrais seance_exercices
        const tmpSteps = [];
        this.seance.steps.forEach((s) => {
          if (s.type === "warmup") {
            s.step.ordre = tmpSteps.length;
            tmpSteps.push(s.step);
          } else {
            s.steps.forEach((subS) => {
              subS.ordre = tmpSteps.length;
              tmpSteps.push(subS);
            });
          }
        });

        this.$set(this.seance, "seance_exercices", tmpSteps);

        this.seance = parseSeance(this.seance);
      }
      return true;
    },
    getInfoSeance() {
      this.forbidden = false;
      if (this.isNew) {
        this.seance = {
          seance_exercices: [],
        };
      } else {
        this.loading = true;
        Api().get(`/seance/${this.$route.params.id}/`)
          .then((res) => {
            // this.seance = res;
            this.seance = parseSeance(res.data);
            this.$set(this.seance, "new_public", this.seance.public);

            this.oldSeanceName = res.data.nom;

            // this.seance.steps = [...this.seance.seance_exercices];
            this.loading = false;
            // this.$nextTick(() => {
            //   const activeIcon = document.querySelector(".icons-list .active");
            //   activeIcon.scrollIntoView({ inline: "center" });
            // });
          })
          .catch(() => {
            this.forbidden = true;
            this.loading = false;
          });
      }
    },
    deleteSeance() {
      Api().delete(`/seance/${this.seance.id}/`)
        .then(() => {
          this.$router.push({ name: "seances" });
          this.$toast("Séance supprimée");
        });
    },
    saveSeance() {
      const data = { ...this.seance };
      data.public = data.new_public;
      delete data.user_created;

      delete data.seance_exercices;

      // if (this.lastStep && this.lastStep.duree_repos !== 0) {
      //   // eslint-disable-next-line no-alert, no-restricted-globals
      //   const remove = confirm("Voulez-vous retirer le repos sur la dernière série ? (à la fin de la séance)");
      //   if (remove) {
      //     this.lastStep.duree_repos = 0;
      //   }
      // }

      this.lastStep.duree_repos = 0;

      let promise = null;

      if (this.isNew) {
        promise = Api().post("/seance/", data);
      } else {
        promise = Api().patch(`/seance/${this.seance.id}/`, data);
      }

      this.loading = true;
      return promise
        .then((res) => {
          if (!this.seance.seance_exercices || !this.seance.seance_exercices.length) {
            this.$toast("Séance sauvegardée");
            this.$router.push({ name: "seance-details", params: { id: res.data.id } });
            return true;
          }

          return Api().post(
            `/seance/${res.data.id}/maj-steps/`,
            { steps: this.seance.seance_exercices },
          ).then(() => {
            if (this.isNew) {
              this.$router.push({ name: "seance-details", params: { id: res.data.id } });
            } else {
              this.getInfoSeance();
            }
            this.$toast("Séance sauvegardée");
          });
        })
        .catch(catchError)
        .finally(() => {
          this.loading = false;
        });
    },
    deleteSteps(stepIds) {
      this.seance.seance_exercices = this.seance.seance_exercices.filter((s) => !stepIds.includes(s.id || s.uuid));
      this.seance = parseSeance(this.seance);
    },
    deleteGroup(group) {
      if (group.type === "exo") {
        this.deleteSteps(group.steps.map((s) => s.id || s.uuid));
      } else {
        this.deleteSteps([group.step.id || group.step.uuid]);
      }
    },
    addEchauffement() {
      this.seance.seance_exercices.push({
        uuid: uuid(),
        type: "warmup",
      });
      this.seance = parseSeance(this.seance);
      this.$nextTick(() => {
        const step = document.querySelector(
          `#step-index-${this.seance.steps.length - 1}`,
        );
        step.scrollIntoView({ behavior: "smooth" });
      });
    },
    addExo(exo) {
      this.addingExo = false;

      this.seance.seance_exercices.push({
        type: "exo",
        uuid: uuid(),
        exercice: exo,
      });
      this.seance = parseSeance(this.seance);

      this.$nextTick(() => {
        const step = document.querySelector(
          `#step-index-${this.seance.steps.length - 1}`,
        );
        step.scrollIntoView({ behavior: "smooth" });
      });
    },
    duplicate(groupStep) {
      const step = groupStep.steps.at(-1);

      const copyStep = {
        ...step, uuid: uuid(), serie: step.serie + 1, id: null,
      };

      const id = step.id || step.uuid;
      const index = this.seance.seance_exercices.findIndex((se) => se.id === id || se.uuid === id);

      this.seance.seance_exercices.splice(index + 1, 0, copyStep);
      this.seance = parseSeance(this.seance);
    },
    toggleSuperSet(group) {
      this.$set(group, "superset", !group.superset);

      group.steps.forEach((s) => {
        this.$set(s, "superset", !s.superset);
      });
    },
    unShare(userId) {
      Api().post(`/seance/${this.seance.id}/retirer_partage/`, { user_id: userId })
        .then(() => {
          this.$toast("Partage retiré");
          this.getInfoSeance();
        })
        .catch(catchError);
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/variables.scss" as var;

.seance_exercices {
  margin-top: 2rem;
}

.exo-order {
  ul{
    margin-top: 2rem;
  }
}

.exo-item {
  cursor: pointer;
  background:var.$background-gray;
  padding: 1rem;
  border-radius: var.$border-radius;
  display: flex;
  align-items: center;
  a {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    color: inherit;
    text-decoration: none;
  }
  .handle {
    margin-right: 1rem;
  }
  margin-bottom: 1rem;

  > * {
    text-align: center;
    &:not(:first-child) {
      margin-left: 10px;
    }
  }
  .title {
    font-weight: bold;
    width: 35%;
  }
}

.btns-add {
  margin-top: 2rem;
  display: flex;
  justify-content: space-around;
  gap: 1rem;
  button {
    background: none;
    border: 1px solid var.$border-gray;
    padding: 1rem;
    border-radius: var.$border-radius;
    cursor: pointer;
    width: 40%;
    .fa {
      display: block;
    }
    &:hover {
      background: var.$border-gray !important;
    }
  }
}

.drag-info {
  font-style: italic;
  margin-top: 2rem;
}
.steps {
  input {
    width: 3rem;
    text-align: center;
    border: 0;
    background: none;
    border-bottom: 1px solid var.$border-gray;
  }
  li {
    border-left: 2px solid transparent;
    padding: 0.5rem;
    &.dragging {
      border: 2px solid var.$red;
    }
  }
  .suppr {
    color: var.$red;
    padding: 2.5px 1rem;
    cursor: pointer;
    flex: 0;
  }
}

li.step {
  padding: 1rem 0;
  position: relative;
  & + .step {
    border-top: 1px solid var.$border-gray;
  }
  &.warmup {
    text-align: center;
  }
  textarea {
    width: 90%;
    margin-top: 0.5rem;
  }
}

.see-body-container {
  display: flex;
  justify-content: flex-end;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
}

.suppr-repos {
  height: 1.1rem;
  cursor: pointer;
  font-size: 0.8rem;
  z-index: 10;
  position: relative;
  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.repos-input input {
  border-radius: 0;
}
.repos-input, .avec-repos {
  margin-left: 0.5rem;
}
.less-opacity {
  opacity: 0.3;
}
.exo {
  text-align: center;
}
.serie {
  margin-right: 1rem;
}
.exo-name {
  font-weight: bold;
}
.superset-toggle {
  background: white;
  border: 1px solid var.$border-gray;
  position: absolute;
  cursor: pointer;
  right: 0;
  bottom: 0;
  transform: translateY(50%);
  border-radius: var.$border-radius;
  padding: 0.5rem;
  z-index: 10;
  &.active {
    color: green;
  }
}

.superset-help {
  margin-top: 1rem;
  color: green;
}

#seance_details {
  .horizontal-fields {
    margin: 1rem 0;
  }
}

#seance_details .modifiers {
  justify-content: center;
  gap: 1rem;
  > div {
    display: block;
    width: unset;
    padding: 0.5rem;
    border-radius: 0.6rem;
  }
}

#seance_details .form-field.icons {
  display: block;
  border : 1px solid var.$border-gray;
  border-radius: 0.5rem;
  position: relative;
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;

  .icons-list {
    overflow-y: hidden;
    white-space: nowrap;
    overflow-x: auto;
    margin-top: 1rem;
    gap: 0.5rem;
    & > img {
      height: 5rem;
      width: 5rem;
      padding: 0.2rem;
      border: 1px solid transparent;
      cursor: pointer;
      &:hover {
        border-color: black;
      }
      &.active {
        border: 2px solid var.$red;
      }
    }
  }
}

.suppr-seance {
  margin-top: 2rem;
}

#shared_seance_user {
  ul {
    list-style: initial;
    padding-left: 2rem;
  }
}
</style>
