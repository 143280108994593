<template>
  <div>
    <div>
      <a
        @click="$emit('stop', )"
        class="blue-link"
      >
        <i class="fa fa-arrow-left" />
        Retourner à la liste des séances
      </a>
    </div>
    <template>
      <h2>
        Importer une séance par QRCode
      </h2>
      <p>
        Cette fonctionnalité permet de partager des séances entre sportifs.
        Il suffit d'afficher le QRCode depuis la liste des séances et de le scanner ici.
      </p>
      <div v-if="scaning">
        <div class="camera">
          <div
            v-if="cameraLoading"
            class="wait-camera"
          >
            <div
              class="img-container"
            >
              <div>
                <h2>Veuillez patienter<br />chargement de la caméra</h2>
                <img :src="require('@/assets/camera.png', )" />
              </div>
            </div>

          </div>
          <QrcodeStream
            @init="initCamera"
            @decode="handleScanSuccess"
          />
        </div>
        <div class="button-container">
          <button @click="scaning = false;">Annuler</button>
        </div>
      </div>
      <div
        v-else
        class="button-container"
      >
        <button @click="scaning=true">Scanner</button>
      </div>
    </template>
  </div>
</template>

<script>
import Api from "@/modules/axios";
import { catchError } from "@/utils";

export default {
  name: "SeanceQrCode",
  props: {
  },
  components: {
    QrcodeStream: () => import("vue-qrcode-reader").then((res) => res.QrcodeStream),
  },
  data() {
    return {
      scaning: false,
      cameraLoading: false,
    };
  },
  methods: {
    initCamera(promise) {
      this.cameraLoading = true;
      promise.then(() => {
        this.cameraLoading = false;
      });
    },
    handleScanSuccess(token, uuid) {
      const seanceUuid = uuid || token.replace("seance-", "");
      this.scaning = false;
      Api().post("/seance/partage/", { uuid: seanceUuid })
        .then((res) => {
          this.$emit("success", res.data);
        })
        .catch(catchError);
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/variables.scss" as var;

#qrcode {
  h2 {
    margin-bottom: 0;
  }
}

.camera {
  position: relative;
  .wait-camera {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; height: 100%;
    .img-container > div img {
      height: 50%;
    }
  }
}
.qrcode-stream-wrapper{
  border-radius: var.$border-radius;
  overflow: hidden;
}
</style>
