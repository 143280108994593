<template>
  <PulseLoader
    v-if="loading"
    loading
  />
  <DeletedElementsList
    v-else-if="displayDeleted"
    title="Seances supprimées"
    model="seance"
    @cancel="displayDeleted=false; getInfos()"
  />
  <SeanceQrCodeScan
    v-else-if="QrCodeMode"
    @success="successScan"
    @stop="QrCodeMode = false; qrCodeSeance=null;"
  />
  <div v-else>
    <h2>
      Liste des séances
    </h2>
    <div
      v-if="seances.length > 5"
      class="button-container top"
    >
      <router-link :to="{name: 'seance-details', params: {id: 'new', },}">
        <button>Créer une séance</button>
      </router-link>
      <button
        @click="QrCodeMode = true;"
        class="btn outline"
      >
        <i class="fa fa-camera"/>
      </button>
    </div>
    <div class="search-bar">
      <input
        class=""
        v-model="searchText"
        type="text"
        placeholder="Rechercher une séance par son nom"
        @keyup.enter="currentPage=1;getInfos()"
      />
      <i
        class="fa fa-search"
        @click="currentPage=1;getInfos()"
      />
    </div>
    <ul
      v-if="seances.length"
      class="seances"
    >
      <li
        v-for="seance, index in seances"
        :key="seance.id"
      >
        <router-link :to="{name: 'seance-details', params: {id: seance.id, }, }">
          <div class="nom">
            <img
              class="icon"
              v-if="seance.icon"
              :src="require(`@/assets/muscles/${seance.icon}.png`, )"
            />
            <div>
              <strong>
                {{ seance.nom }}
              </strong>
              <div v-if="seance.public">
                Fourni par l'Absolu
              </div>
              <div v-else-if="!seance.public && seance.user_created.id !== user.id">
                Partagé par {{seance.user_created.first_name}} {{seance.user_created.last_name}}
              </div>
            </div>
          </div>
          <div class="counter">
            {{ index === 0 ? "Réalisé" : ""}}<br v-if="index === 0" />{{seance.done_count}}x
          </div>
          <MenuDropdown
            :options="[
              {
                title: (!seance.public && seance.user_created.id === user.id) ? 'Modifier' : 'Consulter',
                handler: () => $router.push({ name: 'seance-details', params: {id: seance.id, }, }, ),
                icon: 'fa-eye',
              },
              {
                title: 'Historique',
                handler: () => $router.push({ name: 'seance-suivi', params: { id: seance.id, }, }, ),
                icon: 'fa-chart-line',
              },
              (!seance.public && seance.user_created.id === user.id) ? {
                title: 'Partager',
                handler: () => $router.push({ name: 'seance-qr', params: { id: seance.id, }, }, ),
                icon: 'fa-share-alt',
              } : null,
              (!seance.public && seance.user_created.id !== user.id) ? {
                title: 'Retirer le partage',
                handler: () => unShare(seance,),
                icon: 'fa-unlink',
              } : null,
              {
                title: 'Dupliquer',
                handler: () => dupliquer(seance,),
                icon: 'fa-copy',
              },
              (!seance.public && seance.user_created.id === user.id) ? {
                title: 'Archiver',
                handler: () => archiveSeance(seance, ),
                icon: 'fa-trash-alt',
              } : null,
            ]"
          />
        </router-link>
      </li>
    </ul>
    <div
      v-else
      class="img-container tiny"
    >
      <div>
        <h2>Aucune séance {{searched ? "ne correspond à votre recherche" : ""}}</h2>
        <img :src="require('@/assets/empty.png', )" />
      </div>
    </div>
    <div class="button-container">
      <router-link :to="{name: 'seance-details', params: {id: 'new', },}">
        <button class="create-seance">Créer une séance</button>
      </router-link>
      <button
        @click="QrCodeMode = true;"
        class="btn outline"
      >
        <i class="fa fa-qrcode"/>
      </button>
    </div>
    <Pagination
      v-model="currentPage"
      :total-results="resultsCount"
      @paginate="getInfos"
    />
    <div class="display-deleted">
      <button @click="displayDeleted=true">
        Afficher les éléments supprimés
      </button>
    </div>
  </div>
</template>

<script>
import Api from "@/modules/axios";
import SeanceQrCodeScan from "@/components/seance/SeanceQrCodeScan.vue";
import DeletedElementsList from "@/components/DeletedElementsList.vue";
import Pagination from "@/components/Pagination.vue";
import MenuDropdown from "@/components/MenuDropdown.vue";
import { mapGetters } from "vuex";
import { catchError } from "@/utils";

export default {
  name: "Seances",
  components: {
    SeanceQrCodeScan,
    DeletedElementsList,
    Pagination,
    MenuDropdown,
  },
  data() {
    return {
      seances: [],
      loading: false,
      QrCodeMode: false,
      verifingSeance: null,
      displayDeleted: false,

      searchTimeout: null,
      searchText: "",
      searched: false,
      currentPage: 1,
      resultsCount: 0,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  mounted() {
    if (!this.user.beta_accepted) {
      alert("Vous n'êtes pas autorisé à accéder à cette page");
      return this.$router.push({ name: "accueil" });
    } else {
      this.$store.commit("setAvatarShow", false);
      this.getInfos();
      return true;
    }
  },
  methods: {
    dupliquer(seance) {
      Api().post(`/seance/${seance.id}/copy/`)
        .then((res) => {
          this.$router.push({ name: "seance-details", params: { id: res.data.id } });
          this.$toast("Séance dupliquée");
        });
    },
    getInfos() {
      if (!window.isTuto) {
        this.loading = true;
        Api().get(`/seance/?search=${this.searchText}&page=${this.currentPage}&page_size=15`)
          .then((res) => {
            this.searched = !!this.searchText;
            this.seances = res.data.results;
            this.resultsCount = res.data.count;
            this.loading = false;
          });
      }
    },
    successScan() {
      this.QrCodeMode = false;
      this.qrCodeSeance = null;
      this.getInfos();
      this.$toast("Vous avez maintenant accès à cette séance !");
    },
    unShare(seance) {
      Api().post(`/seance/${seance.id}/retirer_partage/`)
        .then(() => {
          this.$toast("Seance déliée");
          this.getInfos();
        })
        .catch(catchError);
    },
    archiveSeance(seance) {
      Api().delete(`/seance/${seance.id}/`)
        .then(() => {
          this.$toast("Séance archivée");
          this.getInfos();
        })
        .catch(catchError);
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/variables.scss" as var;

.seances {
  li + li {
    margin-top: 1rem;
  }
  li > a {
    text-decoration: none;
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background:var.$background-gray;
    padding: 1rem;
    border-radius: var.$border-radius;
    cursor: pointer;
    .fa {
      background: var.$red;
      color: white;
      padding: 1rem;
      font-size: 1rem;
      border-radius: var.$border-radius;
      &.fa-share-alt {
        background: lighten(var.$red, 20%);
      }
    }
    .nom {
      width: 50%;
      display: flex;
      align-items: center;
    }
    .counter {
      width: 30%;
      text-align: center;
    }
    .buttons {
      // width: 20%;
      text-align: right;
      display: flex;
      a + a {
        margin-left: 0.5rem;
      }
    }

  }
  li .icon {
    width: 4rem;
    height: 4rem;
    margin-right: 1rem;
  }
}
</style>
