<template>
  <div
    v-if="seance"
    class="seance-suivi"
  >
    <router-link
      :to="{name: 'seances', }"
      class="blue-link"
    >
      <i class="fa fa-arrow-left" />
      Retourner à la liste des séances
    </router-link>
    <h2>
      Suivi de la séance<br />"{{seance.nom}}"
    </h2>
    <div>
      <div
        v-for="exo, key in exercices"
        :key="key"
      >
        <h3>{{key}}&nbsp;:</h3>
        <div v-if="exo.last">
          <strong>Dernière performance&nbsp;:</strong>
          {{exo.last.reps}} x {{exo.last.poids}}kg
          <div class="date">
            ({{exo.last.date | moment("dddd DD/MM/YY [à] HH:mm:ss")}})
          </div>
        </div>
        <ExerciceStats :exerciceId="exo.id" />
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/modules/axios";
import ExerciceStats from "@/components/ExerciceStats.vue";

export default {
  name: "SeanceSuivi",
  components: {
    ExerciceStats,
  },
  data() {
    return {
      seance: false,
    };
  },
  mounted() {
    this.getSeance();
  },
  computed: {
    exercices() {
      if (!this.seance) {
        return [];
      }

      const exos = {};

      this.seance.seance_exercices.forEach((s) => {
        if (
          s.exercice && (
            !exos[s.exercice.nom]
            || exos[s.exercice.nom].poids < s.last_perfs.last.poids
          )
        ) {
          exos[s.exercice.nom] = {
            last: s.last_perfs.last,
            id: s.exercice.id,
          };
        }
      });

      return exos;
    },
  },
  methods: {
    getSeance() {
      Api().get(`/seance/${this.$route.params.id}/`)
        .then((res) => {
          this.seance = res.data;
        });
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/variables.scss" as var;

.seance-suivi {
  table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    td {
      padding: 1rem;
    }
    thead {
      font-weight: bold;
      td {
        // border: 1px solid black;
        background: var.$red;
        color: white;
      }
    }
  }
  .date {
    opacity: 0.8;
    // display: inline-block;
    &:first-letter {
      text-transform: capitalize;
    }
  }
  h3 {
    text-decoration: underline;
  }
}
</style>
